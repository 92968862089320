<template>
  <div v-if="question">
    <sqr-input-checkboxes
      :label="question.name"
      :required="question.required"
      v-model="ticks"
      :options="question.options"
      :disabled="disabled"
      :v="v"
    />
  </div>
</template>

<script>
import { contains } from 'ramda';
import SqrInputCheckboxes from '@/sqrd-ui/SqrInputCheckboxes';
export default {
  name: 'CheckboxView',
  components: { SqrInputCheckboxes },
  props: {
    question: { type: Object },
    answer: { type: Array },
    disabled: { type: Boolean },
    v: { type: Object },
  },
  computed: {
    ticks: {
      get() {
        return this.answer?.map(ans => ans.id) ?? [];
      },
      set(ids) {
        const opts = this.question?.options?.filter(opt =>
          contains(opt.id, ids)
        );
        if (opts) {
          this.$emit('change', opts);
        }
      },
    },
  },
};
</script>
